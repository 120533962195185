.content {
  --padding-top: 2rem;
  --padding-start: 2rem;
  --padding-end: 2rem;
  --padding-bottom: 2rem;
}

.list-md {
  margin: 2rem 0;
}
